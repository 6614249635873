import Vue from "vue";
import VueRouter from "vue-router";


const originalPush = VueRouter.prototype.push
 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/**
 * 再不想创建一个单独的VUE文件时，可以用以下代码实现
 * component: resolve => require(['../pages/home.vue'], resolve)
 * 如果用import引入的话，当项目打包时路由里的所有component都会打包在一个js中，造成进入首页时，需要加载的内容过多，时间相对比较长。
  当你用require这种方式引入的时候，会将你的component分别打包成不同的js，加载的时候也是按需加载，只用访问这个路由网址时才会加载这个js。
*/

Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "Home",
  component: () => import('@/views/Home.vue'),
  meta: {},
  children: []
},
{
  path: "/CourseSelection",
  name: "CourseSelection",
  component: () => import('@/views/CourseSelection.vue'),
  meta: {},
  children: []
},
{
  path: "/TeacherHall",
  name: "TeacherHall",
  component: () => import('@/views/TeacherHall.vue'),
  meta: {},
  children: []
},
{
  path: "/TeacherDetail/:id",
  name: "TeacherDetail",
  component: () => import('@/views/TeacherDetail.vue'),
  meta: {},
  children: []
},
{
  path: "/Betreff",
  name: "Betreff",
  component: () => import('@/views/Betreff.vue'),
  meta: {},
  children: []
},
{
  path: "/News",
  name: "News",
  component: () => import('@/views/News.vue'),
  meta: {},
  children: []
},
{
  path: "/NewsDetail/:id",
  name: "NewsDetail",
  component: () => import('@/views/NewsDetail.vue'),
  meta: {},
  children: []
},
{
  path: "/AboutUs",
  name: "AboutUs",
  component: () => import('@/views/AboutUs.vue'),
  meta: {},
  children: []
},
{
  path: "/ContactUs",
  name: "ContactUs",
  component: () => import('@/views/ContactUs.vue'),
  meta: {},
  children: []
},
{
  path: "/Curriculum/:id",
  name: "Curriculum",
  component: () => import('@/views/Curriculum.vue'),
  meta: {},
  children: []
},
{
  path: "/Search",
  name: "Search",
  component: () => import('@/views/Search.vue'),
  meta: {},
  children: []
},
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }

});

export default router;
