<template>
  <div id="top">
    <div class="top_noLogin fixed">
      <div class="topT">
        <div class="content d-flex d-flex-middle d-flex-between">
          <p class="color3 fontSize12">欢迎来到湖北安保培训学校！</p>
          <div class="d-flex d-flex-middle d-flex-end">
            <p class="color3 fontSize12">服务热线：</p>
            <p class="fontSize12 tel">027-87688608</p>
          </div>
        </div>
      </div>
      <div class="topB">
        <div class="content d-flex d-flex-middle d-flex-between">
          <div class="logoDiv d-flex d-flex-middle">
            <router-link to="/"><img src="../assets/images/logo.png" /></router-link>
            <div class="logoDiv_txt">
              <p>湖北安保</p>
              <p class="color3 fontSize12">考证就上湖北安保</p>
            </div>
          </div>
          <div class="topB_m">
            <div class="topB_m_search d-flex d-flex-middle d-flex-between">
              <div class="topB_m_search_select d-flex d-flex-middle d-flex-center" @mousemove="mousemoveSelect" @mouseout="showPop=false" @click="showPop=false">
                <p>{{selectTitle}}</p>
                <ul v-show="showPop">
                  <li v-for="(item,index) in selectTypeList" :key="index" @click="changeSelectType(item.id)">{{item.value}}</li>
                </ul>
              </div>
              <div class="topB_m_search_inp d-flex d-flex-middle d-flex-center">
                <input type="text" id="searchVal" v-model="searchVal" value="" placeholder="请输入搜索关键字" />
              </div>
              <p class="topB_m_search_btn" @click="handSelrch"></p>
            </div>
            <div class="d-flex d-flex-middle d-flex-between" v-if="hisSearchList.length>0">
              <div class="topB_m_alinks d-flex d-flex-middle d-flex-wrap">
                <span v-for="(item,index) in hisSearchList" :key="index" @click="goHisTory(item)" class="click txtOver">{{item.searchValue}}</span>
              </div>
              <div class="del d-flex d-flex-middle d-flex-end" @click="delHisTory">
                <img src="../assets/images/del.png"/>
                <p class="color9 fontSize12">清空</p>
              </div>
              
            </div>
          </div>
          <div class="topB_userInfo d-flex d-flex-middle">
            <img src="../assets/images/userImg.png" />
            <div class="top_userInfo_txt">
              <p class="color3 fontSize14">欢迎来到湖北安保</p>
              <div class="top_userInfo_alinks d-flex d-flex-middle">
                
服务热线：

027-87688608
                <!-- <a href="" class="fontSize14">登录</a>
                <a href="" class="fontSize14">新用户注册</a> -->
              </div>
              <p class="top_userInfo_login color3 fontSize14 hide">您好，<a href="">学员164545</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="top_Tabs">
        <div class="content d-flex d-flex-middle">
          <router-link class="item-route" to="/" exact>首页</router-link>
          <router-link class="item-route" to="/CourseSelection">选课中心</router-link>
          <router-link class="item-route" to="/TeacherHall">名师堂</router-link>
          <router-link class="item-route" to="/Betreff">热门真题</router-link>
          <router-link class="item-route" to="/News">新闻资讯</router-link>
          <router-link class="item-route" to="/AboutUs">关于我们</router-link>
          <router-link class="item-route" to="/ContactUs">在线留言</router-link>
        </div>
      </div>
    </div>
    <!-- <div class="topLogin hide">
		<div class="content d-flex d-flex-middle d-flex-between">
			<div class="topLogin_tabs d-flex d-flex-middle">
				<a href="../pages/index.html">首页</a>
				<a href="../pages/CourseSelection.html">选课中心</a>
				<a href="../pages/TeacherHall.html">名师堂</a>
				<a href="../pages/Betreff.html">热门真题</a>
				<a href="../pages/News.html">新闻资讯</a>
				<a href="../pages/AboutUs.html">关于我们</a>
				<a href="../pages/ContactUs.html">在线留言</a>
			</div>
			<a href="" class="to_login hide">登录/注册</a>
			<div class="topLogin_userInfo d-flex d-flex-middle hide">
				<img src="../assets/images/userImg_2.png" />
				<p class="color3 fontSize14">您好，<a href="">学员164545</a></p>
			</div>
		</div>
	</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPop: false,
      selectType: 1,
      selectTitle: '',
      selectTypeList: [{ id: 1, value: '课程' }, { id: 2, value: '真题' }],
      searchVal: '',
      hotCourseList: [],
      hotPapeList: [],
      hisSearchList: [],
    };
  },

  // watch: {
  //   isHomeChangeMenu(newValue, oldValue) {
  //     console.log(newValue, oldValue);
  //     this.isChangeMenu = newValue;
  //   },
  //   deep: true,
  // },

  mounted() {
    //过滤选中的selectTitle
    this.selectTitle = this.selectTypeList.find(n => n.id == this.selectType).value

    // 历史搜索
    this.hisSearchList = JSON.parse(localStorage.getItem('HISTORY_SEARCH')) || []
  },

  methods: {
    mousemoveSelect() {
      this.showPop = true
    },

    changeSelectType(id) {
      this.selectType = id
      //过滤选中的selectTitle
      this.selectTitle = this.selectTypeList.find(n => n.id == this.selectType).value
    },

    handSelrch() {
      if (this.searchVal.trim() == "") {
        alert('请输入要查询的关键词')
        return
      }

      // 记录历史搜索
      this.setHistory(this.selectType, this.searchVal)

      this.$router.push({
        path: '/Search',
        query: { searchType: this.selectType, searchValue: this.searchVal }
      })
    },

    // 记录历史搜索
    setHistory(selectType, searchVal) {
      const data = JSON.parse(localStorage.getItem('HISTORY_SEARCH')) || []
      const index = data.findIndex(n => n.searchType == selectType)
      const index2 = data.findIndex(n => n.searchValue == searchVal)

      //当前搜索的字段存在，就删掉
      index > -1 && index2 > -1 && data.splice(index, 1)

      //只记录4个
      data.length >= 4 && data.splice(3, 1)

      //当前搜索的字段不存在，向数组前面添加
      data.unshift({ searchType: selectType, searchValue: searchVal })
      this.hisSearchList = data
      localStorage.setItem('HISTORY_SEARCH', JSON.stringify(data))
    },

    //点击历史记录跳转
    goHisTory(item) {
      this.$router.push({
        path: '/Search',
        query: { searchType: item.searchType, searchValue: item.searchValue }
      })
    },
    delHisTory(){
      localStorage.clear('HISTORY_SEARCH')
      this.hisSearchList = []
    }
  }

};
</script>

<style>
#top {
  background-color: #fafafa;
  padding-bottom: 200px;
}
#top .fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}
#top .topT {
  background: #f4f8fb;
}
#top .topT .content {
  height: 40px;
}
#top .tel {
  color: #fe7f00;
}
#top .topB {
  background-color: #fff;
}
#top .topB .content {
  height: 120px;
}
#top .logoDiv {
}
#top .logoDiv img {
  width: 64px;
  height: 76px;
  margin-right: 15px;
}
#top .logoDiv .logoDiv_txt p:nth-of-type(1) {
  color: #161c68;
  font-size: 30px;
  font-weight: bold;
}
#top .logoDiv .logoDiv_txt p:nth-of-type(2) {
  letter-spacing: 3.2px;
  margin-top: 5px;
}
#top .topB_m_search {
  width: 480px;
  height: 36px;
  border-radius: 20px;
  border: 2px solid #0087ed;
  background-color: #0087ed;
}
#top .topB_m_search .topB_m_search_select {
  width: 100px;
  height: 36px;
  text-align: center;
  position: relative;
  background-color: #fff;
  border-radius: 18px 0 0 18px;
}
#top .topB_m_search .topB_m_search_select p {
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: url(../assets/images/down_icon_1.png) right 10px center no-repeat;
  background-size: 10px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 10px;
}
#top .topB_m_search .topB_m_search_select ul {
  position: absolute;
  top: 36px;
  left: 15px;
  border: 1px solid #ddd;
  width: calc(100% - 30px);
  background-color: #fff;
}
#top .topB_m_search .topB_m_search_select ul li {
  line-height: 30px;
  cursor: pointer;
}
#top .topB_m_search .topB_m_search_select ul li:hover {
  background-color: #0087ed;
  color: #fff;
}
#top .topB_m_search .topB_m_search_inp {
  height: 36px;
  flex: 1;
  background-color: #fff;
}
#top .topB_m_search input {
  height: 20px;
  line-height: 2px;
  flex: 1;
}
#top .topB_m_search .topB_m_search_btn {
  height: 36px;
  width: 60px;
  cursor: pointer;
  background: url(../assets/images/search_icon_1.png) center no-repeat;
  background-size: 15px;
}
#top .topB_m_alinks {
  margin-top: 15px;
  width: calc(100% - 40px);
}
#top .topB_m_alinks span {
  color: #999;
  font-size: 12px;
  margin-right: 15px;
  /* padding: 0 10px; */
  max-width: 100px;
}
.del{
  margin-top: 15px;
  cursor: pointer;
  width: 40px;
}
.del img{
  width: 16px;
  height: 16px;
}
#top .topB_userInfo {
}
#top .topB_userInfo img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-right: 20px;
}
#top .topB_userInfo p {
  margin-bottom: 10px;
}
#top .topB_userInfo .top_userInfo_alinks a:nth-of-type(1) {
  display: inline-block;
  width: 72px;
  line-height: 24px;
  height: 24px;
  text-align: center;
  color: #fff;
  background-color: #0087ed;
  border-radius: 12px;
}
#top .topB_userInfo .top_userInfo_alinks a:nth-of-type(2) {
  margin-left: 20px;
  color: #fe7f00;
}
#top .top_userInfo_login {
  margin-bottom: 0;
  box-sizing: border-box;
  padding-right: 20px;
  background: url(../assets/images/down_icon_2.png) right center no-repeat;
  background-size: 12px;
}
#top .top_userInfo_login a {
  color: #0087ed;
}
#top .top_Tabs {
  height: 40px;
  background-color: #0091ff;
}
#top .top_Tabs a {
  display: inline-block;
  line-height: 40px;
  padding: 0 50px;
  color: #fff;
  text-align: center;
}
#top .top_Tabs .router-link-active {
  background-color: #0087ed;
}
#top .topLogin {
  background-color: #fff;
  height: 90px;
}
#top .topLogin .content {
  height: 100%;
}
#top .topLogin .topLogin_tabs a {
  color: #333;
  font-size: 14px;
  margin-right: 50px;
}
#top .topLogin .topLogin_tabs .active {
  color: #0087ed;
}
.to_login {
  width: 120px;
  height: 40px;
  line-height: 40px;
  background: #0091ff;
  border-radius: 20px;
  text-align: center;
  color: #fff;
}
#top .topLogin_userInfo {
}
#top .topLogin_userInfo img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}
#top .topLogin_userInfo p {
  box-sizing: border-box;
  padding-right: 20px;
  background: url(../assets/images/down_icon_2.png) right center no-repeat;
  background-size: 12px;
}
#top .topLogin_userInfo p a {
  color: #0091ff;
  font-size: 14px;
}
.topHeight {
  height: 90px;
}
</style>