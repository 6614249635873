<template>
  <div class="right-guide">
    <div class="item item-1">
      <div class="icons icons-1"></div>
      <div class="text">小程序</div>
            <div class="hd-cnt hd-cnt-vx">
        <div class="hd-txt vx-img">
          <img src="../assets/images/qrcod.png" alt="">
        </div>
        <div class="hd-r">
          <div class="hd-ico"></div>
        </div>
      </div>
    </div>
    <div class="item item-2">
      <div class="icons icons-2"></div>
      <div class="text">服务热线</div>
      <div class="hd-cnt">
        <div class="hd-txt">027-87688608</div>
        <div class="hd-r">
          <div class="hd-ico"></div>
        </div>
      </div>
    </div>
    <div class="item item-3">
      <div class="icons icons-3"></div>
      <div class="text">在线咨询</div>
    </div>
    <div class="item item-4" @click="toTop">
      <div class="icons icons-4"></div>
      <div class="text">返回顶部</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gotop: false
    };
  },
  mounted() {
    //此处true 需要加上 不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      let scrolltop =
        document.documentElement.scrolltop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      //实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    }
  }
};
</script>

<style lang="scss" scoped>

.right-guide {
  width: 90px;
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 0px 3px 2px rgba(230, 226, 226, 0.54);
  border-radius: 3px;
  .item {
    display: inline-block;
    width: 90px;
    height: 90px;
    box-shadow: 0px 1px 1px 1px rgba(230, 226, 226, 0.54);
    text-align: center;
    cursor: pointer;
    color: $share-text-color-grey;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    position: relative;
    .icons {
      margin-top: 10px;
      width: 48px;
      height: 48px;
      display: inline-block;
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
    }
    .icons-1 {
     background: url("../assets/images/side_icon_1.png") no-repeat;
      background-size: 100% 100%;
    }
    .icons-2 {
     background: url("../assets/images/side_icon_2.png") no-repeat;
      background-size: 100% 100%;
    }
    .icons-3 {
     background: url("../assets/images/side_icon_3.png") no-repeat;
      background-size: 100% 100%;
    }
    .icons-4 {
     background: url("../assets/images/side_icon_4.png") no-repeat;
      background-size: 100% 100%;
    }
    .text {
      font-size: 16px;
    }
    .hd-cnt {
      position: absolute;
      width: 200px;
      height: 60px;
      left: -188px;
      top: 15px;
      cursor: text;
      display: none;
      .hd-txt {
        font-weight: bold;
        color: #0091ff;
        font-size: 20px;
        text-align: left;
        display: inline-block;
        vertical-align: text-top;
        line-height: 60px;
        background: #ffffff;
    
        border-radius: 5px;
        padding: 0 15px;
        box-shadow: 0 0 8px 1px rgba(185, 185, 185, 0.54);
      }
      .hd-r {
        display: inline-block;
        vertical-align: text-top;
        .hd-ico {
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-left: 10px solid #ffffff;
          
          margin-top: 24px;
        }
      }
    }

    .hd-cnt-vx {
      position: absolute;
      width: 170px;
      height: auto;
      left: -165px;
      top: -30px;
      cursor: text;
      .vx-img {
          width: 150px;
          height: 150px;
        padding: 20px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }

            .hd-r {
        display: inline-block;
        vertical-align: text-top;
        .hd-ico {
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-left: 10px solid #ffffff;
          
          margin-top: 70px;
        }
      }
    }
  }
  .item:hover {
    background: $share-color-primary;
    .hd-cnt {
        display: block;
    }
  }
  .item-1:hover {
    .icons-1 {
     background: url("../assets/images/side_icon_1_.png") no-repeat;
      background-size: 100% 100%;
    }
    color: #ffffff;
  }
  .item-2:hover {
    .icons-2 {
     background: url("../assets/images/side_icon_2_.png") no-repeat;
      background-size: 100% 100%;
    }
    color: #ffffff;
  }
  .item-3:hover {
    .icons-3 {
     background: url("../assets/images/side_icon_3_.png") no-repeat;
      background-size: 100% 100%;
    }
    color: #ffffff;
  }
  .item-4:hover {
    .icons-4 {
     background: url("../assets/images/side_icon_4_.png") no-repeat;
      background-size: 100% 100%;
    }
    color: #ffffff;
  }
}
</style>