

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import 'swiper/dist/css/swiper.css'

import diretivesObj from './utils/directives'
Vue.use(diretivesObj)



import { Button,Carousel, CarouselItem, Select, Notification, Message, Pagination } from 'element-ui'
Vue.use(Button)
Vue.use(Select)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
