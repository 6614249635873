import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    httpUrl:'https://api.hbabxx2008.com/', //$store.state.httpUrl
    videoUrl:'https://video-convert.oss-cn-hangzhou.aliyuncs.com/', //$store.state.videoUrl
  },
  mutations: {},
  actions: {},
  modules: {},
});
