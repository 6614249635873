<template>
  <div>
    <img src="../assets/images/bottomImg.png" class="w100 mb_5" />
    <div id="bottom">
      <div class="bottomT">
        <div class="content d-flex d-flex-center d-flex-top">
          <div class="bottomT_logoInfo">
            <img src="../assets/images/logo.png" />
            <p>全国免费咨询热线</p>
            <p>027-87688608</p>
          </div>
          <div class="bottomT_alinks d-flex d-flex-top d-flex-between">
            <div class="bottomT_alinks_li">
              <p>选课中心</p>
              <router-link to="/CourseSelection">热门课程</router-link>
              <router-link to="/CourseSelection">精品课程</router-link>
              <router-link to="/CourseSelection">课程分类</router-link>
            </div>
            <div class="bottomT_alinks_li">
              <p>帮助中心</p>
              <router-link to="/TeacherHall">名师推荐</router-link>
              <router-link to="/Betreff">真题下载</router-link>
            </div>
            <div class="bottomT_alinks_li">
              <p>新闻资讯</p>
              <router-link to="/News">行业新闻</router-link>
              <router-link to="/News">学校新闻</router-link>
            </div>
            <div class="bottomT_alinks_li">
              <p>关于我们</p>
              <router-link to="/AboutUs">公司简介</router-link>
              <router-link to="/AboutUs">公司资质</router-link>
              <router-link to="/AboutUs">企业文化</router-link>
            </div>
            <div class="bottomT_alinks_li">
              <p>联系我们</p>

              <router-link to="/ContactUs">我要留言</router-link>
              <router-link to="/ContactUs">在线咨询</router-link>
            </div>
          </div>
          <div class="bottomT_qrCode d-flex d-flex-top">
            <div class="bottomT_qrCode_li">
              <img src="../assets/images/qrcod.png" />
              <p>扫码关注官方微信</p>
            </div>
            <div class="bottomT_qrCode_li">
              <img src="../assets/images/qrcod.png" />
              <p>官方小程序二维码</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomB">
        <div class="content d-flex d-flex-middle d-flex-between">
          <p>客服热线：027-87688608/18120409742</p>
          <p>版权所有 湖北安保 © 2021 All Rights Reserved 备案号：湘ICP备15015733号-3</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },

  mounted() {

  },
  methods: {

  },
};
</script>

<style>
.footer {
}
#bottom {
}
#bottom .bottomT {
  background-color: #323232;
  color: #999999;
  font-size: 12px;
  box-sizing: border-box;
  padding: 25px;
}
#bottom .bottomT_logoInfo {
  text-align: center;
}
#bottom .bottomT_logoInfo img {
  width: 64px;
  height: 76px;
}
#bottom .bottomT_logoInfo p:nth-of-type(1) {
  font-size: 16px;
  letter-spacing: 5px;
  margin: 10px 0 5px 0;
}
#bottom .bottomT_logoInfo p:nth-of-type(2) {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
}
#bottom .bottomT_alinks {
  margin: 0 70px 0 90px;
  width: 548px;
}
#bottom .bottomT_alinks_li a {
  line-height: 24px;
  font-size: 14px;
  color: #999;
  display: block;
}
#bottom .bottomT_alinks_li p {
  color: #fff;
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
}
#bottom .bottomT_qrCode_li {
  text-align: center;
  margin: 0 15px;
}
#bottom .bottomT_qrCode_li img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
#bottom .bottomB {
  height: 40px;
  background-color: #222;
  color: #999999;
  font-size: 12px;
}
#bottom .bottomB .content {
  height: 100%;
}
</style>