<template>
  <!-- <Menu/> -->
  <div>
      <router-view/>
  </div>
  <!-- <RightGuide /> -->
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
export default {
  components: {
    Menu,
    Foot,
    RightGuide
  },
  mounted() {
  }
};

</script>

<style lang="scss">
// @import "@/assets/css/share.scss";   //或者在 vue.config.js 里配置
* {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  font-size: 14px;
}

input::-webkit-input-placeholder {
  color: #909399;
  font-size: 14px;
}
input:-moz-placeholder {
  color: #909399;
  font-size: 14px;
}
input::-moz-placeholder {
  color: #909399;
  font-size: 14px;
}
input:-ms-input-placeholder {
  color: #909399;
  font-size: 14px;
}

textarea::-webkit-input-placeholder {
  color: #909399;
  font-size: 14px;
}
textarea:-moz-placeholder {
  color: #909399;
  font-size: 14px;
}
textarea::-moz-placeholder {
  color: #909399;
  font-size: 14px;
}
textarea:-ms-input-placeholder {
  color: #909399;
  font-size: 14px;
}

/**/
a {
  text-decoration: none;
  color: #333;
}
p {
  margin: 0;
}
input {
  outline: none;
  border: none;
  background-color: transparent;
}
/**/
.color {
  color: #0087ed !important;
}
.colorC {
  color: #ccc !important;
}
.colorE {
  color: #eee !important;
}
.color0 {
  color: #000 !important;
}
.color3 {
  color: #333 !important;
}
.color6 {
  color: #666 !important;
}
.color9 {
  color: #999 !important;
}
.colorF {
  color: #fff !important;
}
.bg_color {
  background-color: #0087ed;
}
.bg_colorE {
  background-color: #eeeeee;
}
.bg_f2 {
  background-color: #f2f2f2;
}
.bg_fff {
  background-color: #ffffff !important;
}
/**/
.hide {
  display: none !important;
}
.w100 {
  width: 100%;
}
.mt0 {
  margin-top: 0 !important;
}
.mb15 {
  margin-bottom: 15px;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb100 {
  margin-bottom: 100px;
}
.mb_5 {
  margin-bottom: -5px;
}
.click{
  cursor: pointer;
}
/**/
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: Microsoft Yahei, SimSun, Helvetica;
}
body,
html {
  width: 100%;
  min-height: 100vh;
  background-color: #f4f8fb;
}
/**/
.d-flex {
  display: flex;
}
.d-flex-column {
  flex-direction: column;
}
.d-flex-wrap {
  flex-wrap: wrap;
}
.d-flex-item {
  flex: 1;
}
.d-flex-unShrink {
  flex-shrink: 0;
}
.d-flex-between {
  justify-content: space-between;
}
.d-flex-around {
  justify-content: space-around;
}
.d-flex-center {
  justify-content: center;
}
.d-flex-start {
  justify-content: flex-start;
}
.d-flex-end {
  justify-content: flex-end;
}
.d-flex-middle {
  align-items: center;
}
.d-flex-top {
  align-items: flex-start;
}
.d-flex-bottom {
  align-items: flex-end;
}
.d-flex-align-between {
  align-content: space-between;
}
.d-flex-align-around {
  align-content: space-around;
}
/*  */
.fontSize12 {
  font-size: 12px;
}
.fontSize14 {
  font-size: 14px;
}
.fontSize16 {
  font-size: 16px;
}
.fontSize18 {
  font-size: 18px;
}
.fontSize20 {
  font-size: 20px;
}
.fontSize24 {
  font-size: 24px;
}
.color3_ {
  color: #303133 !important;
}
.color6_ {
  color: #606266 !important;
}
.color8_ {
  color: #8c8d8f !important;
}
.color9_ {
  color: #909399 !important;
}
.b {
  font-weight: bold;
}
/*  */
.content {
  width: 1240px;
  margin: 0 auto;
  height: 100%;
}
.diyImg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.txtOver {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.txtOver2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.txtOver4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* crumbs */
.crumbs {
  height: 80px;
}
.crumbs .and {
  margin: 0 5px;
}
</style>
